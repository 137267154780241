import { A } from "@solidjs/router";
import { Atom } from ":mods";
import { state } from ":mods";
import { getBlog } from "../..";
import { createEffect, Show } from "solid-js";
import { BlogPostPage } from "./blog-post";
export function DescriptionSection() {
  const $blogList = state.create([
    {
      id: 1,
      topic: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
      title: "Topic",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      id: 2,

      topic: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
      title: "Topic",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      id: 3,

      topic: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
      title: "Topic",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      id: 4,

      topic: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
      title: "Topic",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      id: 5,

      topic: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
      title: "Topic",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      id: 6,

      topic: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
      title: "Topic",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ]);
  // const $db_blog = state.createAsync(getBlog);
  // $db_blog.on((event, { value }) => {
  //   $blogList.set(value.data);
  // });
  const $blog_id = state.create(undefined);
  return (
    <section class="flex flex-col  bg#paper pt-40px" id="blogs-list-sections">
      <Show when={$blog_id.value === undefined}>
        <Atom.Microsite.Title bold classes="text-61px my-40px px-90px">
          Our latest news
        </Atom.Microsite.Title>
      </Show>
      <section class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-30px gap-y-80px px-80px ">
        <Show
          when={$blog_id.value === undefined}
          fallback={<BlogPostPage id={$blog_id.value} events={{ onBack: () => $blog_id.set(undefined) }} />}
        >
          {$blogList.value?.map(({ id, topic, title, description }) => {
            return (
              <div
                class="flex flex-col gap-10px w-440px mx-auto"
                onclick={() => {
                  $blog_id.set(id);
                }}
              >
                <div class="bg#p h-277px"></div>
                <p class="text-16px font-bold">{topic}</p>
                <p class="text-17px font-bold leading-22px w-380px">{title}</p>
                <article class="text-16px">{description}</article>
              </div>
            );
          })}
        </Show>
      </section>
      <hr class="max-w-screen w-screen mt-120px h-2px bg#p" />
    </section>
  );
}
